<template>
	<div
			class="badge"
			:class="classes"
	>
		<span class="badge-inner">
			<IconSvg v-if="iconLeft" :icon="iconLeft" :size="14" />
			<span class="text">
				<span v-if="text && text.length > 0" class="text-inner">
					{{ text }}
				</span>
				<slot v-if="!text"></slot>
			</span>
			<IconSvg v-if="iconRight" :icon="iconRight" :size="14" />
		</span>
	</div>
</template>

<script lang="ts" setup>
	import type {PropType} from 'vue';
	import {computed} from 'vue';
	import type {AvailableIcon} from '~/lib/types/IconTypes';
	import IconSvg from '~/components/generic/IconSvg.vue';

	export type BadgeClass =
		'info'
		| 'success'
		| 'warning'
		| 'error'
		| 'neutral'
		| 'minimal'
		| 'primary'
		| 'risk-high'
		| 'risk-medium'
		| 'risk-low';

	const props = defineProps({
		text: {
			type: String,
			default: ''
		},
		iconLeft: {
			type: String as PropType<AvailableIcon>,
			required: false,
			default: null
		},
		iconRight: {
			type: String as PropType<AvailableIcon>,
			required: false,
			default: null
		},
		badgeClass: {
			type: String as PropType<BadgeClass>,
			default: 'neutral'
		}
	});

	const classes = computed(() => {
		return [
			props.badgeClass
		];
	});
</script>

<style scoped>
	.badge {
		--badge-bg: #ebebeb;
		--badge-color: #000000;
		--badge-icon-color: currentColor;

		background: var(--badge-bg);
		color: var(--badge-color);
		padding: var(--badge-padding-y, 4px) var(--badge-padding-x, 8px);
		font-size: .8125rem;
		line-height: 1rem;
		display: inline-block;
		text-decoration: none;
		border-radius: 2em;
		font-weight: 500;
		min-width: var(--badge-min-width, 0);
		max-width: 100%;
	}

	.badge-inner {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		gap: .5rem;
	}

	.badge-inner > :deep(span) {
		display: block;
		flex: 0 0 auto;
	}

	.text {
		text-decoration: none;
		flex: 0 0 auto;
		max-width: 100%;
	}

	.text-inner {
		display: block;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}

	:deep(svg) {
		--icon-size: 1rem;
		width: var(--icon-size);
		height: var(--icon-size);
		fill: var(--badge-icon-color);
	}

	.info {
		--badge-bg: var(--color-info-light);
		--badge-icon-color: var(--color-info);
	}

	.success {
		--badge-bg: var(--color-success-light);
		--badge-icon-color: var(--color-success);
	}

	.warning {
		--badge-bg: var(--color-warning-light);
		--badge-icon-color: var(--color-warning);
	}

	.primary {
		--badge-bg: var(--color-primary-500);
		--badge-icon-color: currentColor;
		--badge-color: #ffffff;
	}

	.minimal {
		--badge-bg: transparent;
		--badge-padding-x: 0;
	}

	.error {
		--badge-bg: var(--color-error-light);
		--badge-icon-color: var(--color-error);
	}

	.risk-high {
		--badge-bg: var(--color-risk-high);
		--badge-icon-color: currentColor;
		--badge-color: #ffffff;
	}

	.risk-medium {
		--badge-bg: var(--color-risk-medium);
		--badge-icon-color: currentColor;
		--badge-color: #000000;
	}

	.risk-low {
		--badge-bg: var(--color-risk-low);
		--badge-icon-color: currentColor;
		--badge-color: #000000;
	}
</style>
